// ALL IMPORTS HERE:
import React from 'react'
import {useParams} from 'react-router-dom'
import Header from '../Header'
import AcademicYearCard from './AcademicYearCard'

//Images and Icons here:
import TwitterIcon from '@material-ui/icons/Twitter'
import FacebookIcon from '@material-ui/icons/Facebook'
import InstagramIcon from '@material-ui/icons/Instagram'
import EmailIcon from '@material-ui/icons/Email'
import LinkedInIcon from '@material-ui/icons/LinkedIn'
import PhoneIcon from '@material-ui/icons/Phone'

// ALL CSS HERE:
import styled from 'styled-components'
import Card from '@material-ui/core/Card'
import Avatar from '@material-ui/core/Avatar'
import Button from '@material-ui/core/Button'

import logo from '../../images/logo.svg'

import useFetchSingleProfile from '../helpers/hooks/usefetchSingleProfile'

const FellowDetailsContainer = styled.div`
	margin: 24px 60px;
`

const DetailCard = styled(Card).attrs(() => ({
	elevation: 2,
}))`
	padding: 10px;
	margin: 15px 15px;
	.MuiCardContent-root {
		padding: 0;
	}
	&.MuiPaper-rounded {
		border-radius: 16px;
	}
	.text {
		font-size: 16px;
		white-space: pre-line;
	}
	.small.text {
		font-size: 16px;
	}
	.budget {
		font-size: 28px;
		font-weight: 600;
		&.raised {
		}
		&.budget-required {
			color: #6e6e6e;
		}
	}
	@media (max-width: 880px) {
		padding: 4px;
		.MuiCardContent-root {
			padding: 18px;
		}
	}
`

const BasicCard = styled(DetailCard)`
	.MuiCardContent-root {
		display: flex;
		grid-template-columns: 1fr 1fr;
		grid-gap: 20px;
		#project-summary {
			grid-column: 1/3;
		}
		@media (max-width: 880px) {
			grid-template-columns: 1fr;
			#project-summary {
				grid-column: 1/2;
			}
		}
	}
`

const SuperBasicCard = styled.div`
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-gap: 20px;

	@media (max-width: 610px) {
		grid-template-columns: 1fr;
		
	}
`

const TitleContainer = styled.div`
	display: flex;
	align-items: center;
	padding: 0 10px;
	margin: 0 20px;
`

const EmailPhoneContainer = styled.div`
	display: flex;
	padding: 0 10px;
	margin: 10px 25px;
`
const Email = styled.div`
	display: flex;
	padding: 0 20px;
	margin: 0px 9px;
`

const Title = styled.h1`
	font-size: 2 em;
	margin: 5px 15px;
`

const ButtonContainer = styled.div`
	display: grid;
	grid-template-columns: 1fr 1fr 1fr 1fr;
	grid-gap: 20px;
	margin-top: 20px;
	margin-bottom: 20px;
	margin-right: 5px;

	@media (max-width: 610px) {
		grid-template-columns: 1fr;
		max-width: 310px;
		
	}
`

const FellowTitle = styled.div`
	padding: 0px 10px;
	margin: 10px, 10px;
	margin-top: 5px;
	margin-left: 7px;
`

const CardDetailsContainer = styled.div`
	display: grid;
	grid-template-columns: 0fr 4.5fr;
	margin-top: 5px;
	grid-gap: 40px;

	.column {
		& > * {
			margin-bottom: 24px;
		}
	}
	.footer {
		grid-column: 1/3;
		margin: 0 auto;
	}

	.acadYear {
		margin: 0px 0px 0px 40px;
	}

	@media (max-width: 880px) {
		margin-top: 0;
		grid-template-columns: 1fr;
		grid-row-gap: 20px;
		grid-column-gap: 0;
		.left {
			grid-row: 2;
		}
		.right {
			grid-row: 1;
		}
	}
`

const FellowDetail = () => {
	const {objectID} = useParams()

	const {isLoading: isProfileLoading, data: fetchedData} =
		useFetchSingleProfile(objectID)

	if (isProfileLoading) {
		return <div>loading..</div>
	}
	const data = fetchedData.hits[0]

	let uniqueAcad = []

	data?.classrooms.forEach(classroom =>
		uniqueAcad.includes(classroom.academicYear)
			? null
			: uniqueAcad.push(classroom.academicYear)
	)

	return (
		<>
			<Header />
			<FellowDetailsContainer>
				<BasicCard>
					{/* <Title> Basic Details</Title> */}

					<CardDetailsContainer>
						<div className="grid-container"></div>
						<div className="grid-container">
							<TitleContainer>
								<Avatar
									aria-label="recipe"
									src={data.publicDetails.pictureURL}
								/>
								<div>
									<Title>
										{data.publicDetails.displayName}
									</Title>
									<FellowTitle>
										{data.publicDetails.fellowTitle}
									</FellowTitle>
								</div>
							</TitleContainer>
							<EmailPhoneContainer>
								<EmailIcon></EmailIcon>
								<Email>{data.publicDetails.email}</Email>
							</EmailPhoneContainer>
							<EmailPhoneContainer>
								<PhoneIcon></PhoneIcon>
								<Email>
									{data.publicDetails.mobilePhone.value}
								</Email>
							</EmailPhoneContainer>

							<ButtonContainer>
								<Button variant="outlined" color="default">
									Portfolio : Fellowship
								</Button>

								{data.projects?.map(project => (
									<Button
										variant="outlined"
										color="secondary"
									>
										<img
											className="logo"
											src={logo}
											alt=""
											width="20"
											height="20"
											float="left"
										/>{' '}
										BTCP: {project.projectName}
									</Button>
								))}
							</ButtonContainer>

							<ButtonContainer>
								<Button
									variant="outlined"
									color="default"
									startIcon={<LinkedInIcon />}
								>
									Linkedin
								</Button>
								<Button
									variant="outlined"
									color="default"
									startIcon={<FacebookIcon />}
								>
									Facebook{' '}
								</Button>
								<Button
									variant="outlined"
									color="default"
									startIcon={<InstagramIcon />}
								>
									Instagram{' '}
								</Button>
								<Button
									variant="outlined"
									color="default"
									startIcon={<TwitterIcon />}
								>
									{' '}
									Twitter
								</Button>
							</ButtonContainer>
						</div>
					</CardDetailsContainer>
				</BasicCard>

				<SuperBasicCard>
					{uniqueAcad.map(item => (
						//console.log({item})

						<BasicCard>
							<CardDetailsContainer>
								<div className="acadYear">
									<h2>{item}</h2>
								</div>
								<AcademicYearCard
									classrooms={data.classrooms}
									academicYear={item}
								/>
							</CardDetailsContainer>
						</BasicCard>
					))}
				</SuperBasicCard>
			</FellowDetailsContainer>
		</>
	)
}

export default FellowDetail
