import {initSearchClient} from '../algoliasearch'
import {useQuery} from 'react-query'

function fetchProfile(queryName, objectID) {
	const algoliaIndex = initSearchClient()

	return algoliaIndex.search({
		filters: `_id:${objectID}`,
		typoTolerance: false,
		responseFields: ['hits'],
	})
}

/**
 * Fetches the data of a single Profile by ID
 * @param {string} objectID The Profile ID to fetch
 */
const useFetchSingleProfile = objectID => {
	const singleProfileQuery = useQuery(
		['singleProfile', objectID],
		fetchProfile,
		{
			staleTime: 10 * 60 * 1000,
			enabled: true,
		}
	)
	return singleProfileQuery
}
export default useFetchSingleProfile
