import React, {useEffect} from 'react'
import {useAuth} from './helpers/authContext'
import styled from 'styled-components'
import {useHistory} from 'react-router-dom'

import Splash from './Splash'

const LoginButtonContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	a,
	a:visited {
		text-decoration: none;
		color: black;
	}
	.help-text {
		margin: 8px;
	}
`
const LoginButton = styled.button`
	background-color: white;
	border-radius: 28px;
	height: 56px;
	border: none;
	font-size: 1.2em;
	display: flex;
	align-items: center;
	cursor: pointer;
	padding: 12px;
	img {
		height: auto;
		width: 30px;
		padding-right: 12px;
	}
	span {
		vertical-align: middle;
	}
`

/**
 * Renders login screen
 */
const Login = () => {
	const history = useHistory()
	const {loginURI, accessToken} = useAuth()
	useEffect(() => {
		if (accessToken) {
			history.replace('/')
		}
	})

	return (
		<Splash>
			<LoginButtonContainer>
				<a href={loginURI}>
					<LoginButton>
						<img src="/google.jpg" alt="google icon" />
						<span>Sign in with Google</span>
					</LoginButton>
				</a>
				<span className="help-text">
					Please use your @teachforindia.org email id
				</span>
			</LoginButtonContainer>
		</Splash>
	)
}
export default Login
