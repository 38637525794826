import React from 'react'
import FellowCard from './Fellow/FellowCard'
import data from './FellowData/data.json'

import FellowDetail from './Fellow/FellowDetail'
import styled from 'styled-components'
import Header from '../components/Header'
import {Link, useHistory, useLocation, Route, Redirect} from 'react-router-dom'
import {InstantSearch, connectHits} from 'react-instantsearch-dom'
import {getSearchClient, indexName} from '../components/helpers/algoliasearch'

const searchClient = getSearchClient()

const CardsContainer = styled.div`
	display: grid;
	margin: 50px;
	grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
	grid-gap: 20px 20px;
`

const Hits = connectHits(({hits}) => (
	<CardsContainer>
		{hits.map(hit => (
			<Link
				to={`/profile/${hit._id}`}
				key={hit.objectID}
				style={{textDecoration: 'none'}}
			>
				<FellowCard data={hit} />
			</Link>
		))}
	</CardsContainer>
))

const Home = () => {
	const onClickCardHandler = () => {
		console.log('I am being developed')
	}

	return (
		<div>
			<Header></Header>

			{/* <CardsContainer>
					{data.map(item => (<FellowCard data={item} />))}
				</CardsContainer> */}

			<InstantSearch searchClient={searchClient} indexName={indexName}>
				<Hits />
			</InstantSearch>
			{/* <FellowDetail data= {data[0]} /> */}
		</div>
	)
}
export default Home
