import React from 'react'

// CSS imports here
import styled from 'styled-components'
import {makeStyles, Theme, createStyles} from '@material-ui/core/styles'
//import Card from "@material-ui/core/Card";
import Paper from '@material-ui/core/Paper'
import CardHeader from '@material-ui/core/CardHeader'
import Avatar from '@material-ui/core/Avatar'
import IconButton from '@material-ui/core/IconButton'
import {red} from '@material-ui/core/colors'
import Typography from '@material-ui/core/Typography'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import CohortIcon from '@material-ui/icons/SchoolOutlined'
import CityIcon from '@material-ui/icons/RoomOutlined'
import SchoolIcon from '@material-ui/icons/LocalLibraryTwoTone'
import {Link, useHistory, useLocation, Route, Redirect} from 'react-router-dom'
import {CardContent, CardActionArea} from '@material-ui/core'

const Card = styled(Paper).attrs(() => ({
	elevation: 4,
}))`
	&.MuiPaper-rounded {
		border-radius: 8px;
	}
`

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		avatar: {
			backgroundColor: red[500],
			width: theme.spacing(9),
			height: theme.spacing(9),
		},

		SubjectBody: {
			display: 'flex',
			padding: '3px',
			margin: '0px 0px 0px 20px',
		},
		SubjectBodyP: {
			margin: '0px',
			padding: '0px 0px 0px 10px',
		},
		IconText: {
			padding: '5px 40px 0px 10px',
		},

		IconTextSchool: {
			padding: '5px 0px 0px 10px',
		},
	})
)

const CardWrapper = styled.div`
	width: 100%;
`

const CardBody = styled.div`
	display: flex;
`

const FellowCard = ({data}) => {
	const classes = useStyles()
	const schoolName = 'Ravishankar School'

	let schoolAcad = []
	const classrooms = data.classrooms
	console.log(classrooms)

	classrooms.map(classroom =>
		schoolAcad.includes(classroom.school)
			? null
			: schoolAcad.push(classroom.school)
	)

	const subheaderCard =
		data.publicDetails.fellowTitle + '    ' + data.classrooms[0]?.school

	return (
		// <Link to={`/fellowDetail`} style={{ textDecoration: 'none' }}>
		<Card className={classes.root}>
			<CardActionArea>
				<CardHeader
					avatar={
						<Avatar
							aria-label="recipe"
							className={classes.avatar}
							src={data.publicDetails.pictureURL}
							alt={data.publicDetails.displayName}
						/>
					}
					title={
						<Typography variant="h6">
							{data.publicDetails.displayName}
						</Typography>
					}
					subheader={
						<div>
							<Typography variant="subtitle2">
								{data.publicDetails.fellowTitle}
							</Typography>
							<Typography variant="subtitle2">
								{data.classrooms[0]?.school}
							</Typography>
						</div>
					}
				/>

				{/* <CardContent>
          <div className={classes.SubjectBody}>
            <CityIcon />{" "}
            <span className={classes.IconText}> {data.publicDetails.city}</span>
            <CohortIcon />{" "}
            <span className={classes.IconText}>
              {" "}
              {data.publicDetails.cohort}
            </span>
          </div>
          <div className={classes.SubjectBody}>
            <SchoolIcon />
            <span className={classes.IconTextSchool}>
              {data.classrooms[0]?.school}
            </span>
          </div>
        </CardContent> */}
			</CardActionArea>
		</Card>
	)
}

export default FellowCard
