import {lazy} from 'react'
import logo from './logo.svg'
import './App.css'
import {AuthProvider} from '../src/components/helpers/authContext'
import {BrowserRouter as Router, Switch, Route} from 'react-router-dom'

import Home from '../src/components/Home'
import Login from '../src/components/Login'
import FellowDetail from './components/Fellow/FellowDetail'
import Search from './components/Search'
import {ReactQueryCacheProvider, QueryCache} from 'react-query'

import data from './components/FellowData/data.json'

// Authentication ---------
// function App() {
//   return (
//     <Router>
//       <AuthProvider>
//         <Switch>
//           <Route
//             exact
//             path={['/']}
//           >
//             <Home />
//           </Route>
//            <Route path="/login">
//             <Login />
//           </Route>
//         </Switch>
//       </AuthProvider>
//     </Router>

//   );

// }
const queryCache = new QueryCache()

function App() {
	return (
		<Router>
			<ReactQueryCacheProvider queryCache={queryCache}>
				<AuthProvider>
					<Switch>
						<Route exact path={['/']}>
							{' '}
							<Home />
						</Route>
						<Route path="/login">
							{' '}
							<Login />{' '}
						</Route>
						<Route path="/profile/:objectID">
							<FellowDetail />
						</Route>
						<Route path="/search">
							<Search />
						</Route>
					</Switch>
				</AuthProvider>
			</ReactQueryCacheProvider>
		</Router>
	)
}

export default App
