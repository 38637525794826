import React from 'react'

import styled from 'styled-components'
import Typography from '@material-ui/core/Typography'

const Label = styled(Typography).attrs(() => ({
	variant: 'h5',
}))`
	&.MuiTypography-h5 {
		font-size: 18px;
		text-transform: uppercase;
		font-weight: bold;
		color: rgba(0, 0, 0, 0.7);
	}
`

const CardBlock = styled.div`
	display: flex;
	margin: 5px;

	.grid-container {
		display: flex;
		grid-template-columns: auto auto auto auto;
		grid-gap: 10px;
		padding: 5px;
	}
`
const formatter = new Intl.ListFormat('en', {
	style: 'long',
	type: 'unit',
})

function AcademicYearCard({classrooms, academicYear}) {
	let schoolAcad = []
	let classroomAcad = []
	let gradeAcad = []
	let subjectAcad = []

	let school = ' '
	let classroomsAcad = classrooms.filter(classroom =>
		classroom.academicYear.includes(academicYear)
	)
	console.log(classroomsAcad)
	classroomsAcad.map(classroom =>
		schoolAcad.includes(classroom.school)
			? null
			: schoolAcad.push(classroom.school) &&
			  classroomAcad.includes(classroom.classroom)
			? null
			: classroomAcad.push(classroom.classroom) &&
			  gradeAcad.includes(classroom.grade)
			? null
			: gradeAcad.push(classroom.grade) &&
			  subjectAcad.includes(classroom.subjects)
			? null
			: subjectAcad.push(classroom.subjects)
	)
	schoolAcad.map(sch => school.concat(sch))

	console.log('New School')
	console.log(subjectAcad)

	return (
		<div>
			<CardBlock>
				<div className="grid-container">
					<Label> School: </Label>
					<div className="text">{schoolAcad.join(',')}</div>
				</div>
			</CardBlock>
			<CardBlock>
				<div className="grid-container">
					<Label> Classroom: </Label>
					<div className="text">{classroomAcad.join(',')}</div>
				</div>
			</CardBlock>

			<CardBlock>
				<div className="grid-container">
					<Label> Grade: </Label>
					<div className="text">{gradeAcad.join(',')}</div>
				</div>
			</CardBlock>

			<CardBlock>
				<div className="grid-container">
					<Label> Subjects </Label>
					<div className="text">{subjectAcad.join(',')}</div>
				</div>
			</CardBlock>
		</div>
	)
}

export default AcademicYearCard
