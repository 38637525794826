import React from 'react'
import styled from 'styled-components'
import Paper from '@material-ui/core/Paper'
import InputAdornment from '@material-ui/core/InputAdornment'
import TextField from '@material-ui/core/TextField'
import SearchIcon from '@material-ui/icons/Search'

const SearchBoxContainer = styled(Paper).attrs(() => ({
	elevation: 0,
	component: 'form',
}))`
	margin: 0 auto;
	padding: 4px 8px;
	width: 30vw;
	min-width: 300px;
	box-sizing: border-box;

	.MuiOutlinedInput-input {
		padding: 12px 21.5px;
	}

	.MuiOutlinedInput-root {
		border-radius: 21.5px;
	}

	@media (max-width: 880px) {
		width: 100vw;
	}
`

const SearchBox = ({currentRefinement, isSearchStalled, refine}) => {
	return (
		<SearchBoxContainer
			onSubmit={e => {
				e.preventDefault()
			}}
		>
			<TextField
				variant="outlined"
				placeholder="Search Fellows"
				value={currentRefinement}
				onChange={e => refine(e.target.value)}
				InputProps={{
					startAdornment: (
						<InputAdornment position="start">
							<SearchIcon />
						</InputAdornment>
					),
				}}
				fullWidth
			/>
		</SearchBoxContainer>
	)
}

export default SearchBox
