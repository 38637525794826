import React from 'react'
import {useState, useEffect} from 'react'
import SearchBox from '../components/Search/SearchBox'
import {Link} from 'react-router-dom'

import styled from 'styled-components'
import Button from '@material-ui/core/Button'
import FilterIcon from '@material-ui/icons/FilterList'
import Accordion from '@material-ui/core/Accordion'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import {Close} from '@material-ui/icons'
import CheckBoxIcon from '@material-ui/icons/CheckBox'
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'

import {
	InstantSearch,
	connectHits,
	connectSearchBox,
	connectRefinementList,
} from 'react-instantsearch-dom'
import {getSearchClient, indexName} from '../components/helpers/algoliasearch'
import Header from '../components/Header'
import FellowCard from '../components/Fellow/FellowCard'
import Typography from '@material-ui/core/Typography'

const searchClient = getSearchClient()
const ConnectedSearchBox = connectSearchBox(SearchBox)

const ResultsContainer = styled.div`
	display: flex;
	position: relative;

	.hitsWrapper {
		width: 75%;
	}

	.filterMenu {
		position: sticky;
		top: 80px;
		flex: 240px 0 0;
		margin-right: 24px;
		max-height: calc(100vh - 80px);
		overflow-y: auto;
		overflow-x: hidden;

		width: 25%;

		& .MuiListItemIcon-root {
			min-width: 32px;
		}

		.closeButton {
			display: none;
		}

		@media (max-width: 880px) {
			display: ${({showFilters}) => (showFilters ? 'block' : 'none')};
			position: fixed;
			top: 56px;
			width: 100vw;
			height: calc(100vh - 56px);
			background-color: rgba(139, 87, 42, 0.3);
			z-index: 1;

			.closeButton {
				display: initial;
				position: fixed;
				bottom: 0;
				width: 100%;
			}

			.filterHeading {
				padding: 1rem;
				background-color: white;
			}
		}
	}
`

const FilterButton = styled.div`
	display: flex;
	justify-content: center;
	@media (min-width: 880px) {
		display: none;
	}
	padding: 8px 0;
`

const Search = () => {
	const [showFilters, setShowFilters] = useState(false)

	const secondaryFacetAttributes = [
		{algoliaAttribute: 'publicDetails.cohort', uiLabel: 'Cohort'},
		{algoliaAttribute: 'publicDetails.city', uiLabel: 'City'},
		{algoliaAttribute: 'classrooms.school', uiLabel: 'School'},
		{algoliaAttribute: 'classrooms.grade', uiLabel: 'Grade'},
		{algoliaAttribute: 'classrooms.subjects', uiLabel: 'Subjects'},
	]
	return (
		<div>
			<Header />
			<InstantSearch searchClient={searchClient} indexName={indexName}>
				<ConnectedSearchBox />
				<FilterButton>
						<Button
							startIcon={<FilterIcon />}
							variant="outlined"
							onClick={() => setShowFilters(true)}
						>
							Filters
						</Button>
					</FilterButton>

				<ResultsContainer showFilters={showFilters}>
					<div className="filterMenu">
						
						<Typography className="filterHeading" variant="h6">
							Filters
						</Typography>
						{secondaryFacetAttributes.map(
							({algoliaAttribute, uiLabel}) => (
								<SecondaryRefinementList
									attribute={algoliaAttribute}
									key={algoliaAttribute}
									label={uiLabel}
								/>
							)
						)}
						<Button
							className="closeButton"
							variant="contained"
							size="large"
							color="primary"
							onClick={() => setShowFilters(false)}
						>
							Close
						</Button>
					</div>
					<div className="hitsWrapper">
						
						<Hits />
					</div>
				</ResultsContainer>
			</InstantSearch>
		</div>
	)
}

export default Search

const HitsContainer = styled.div`
	display: grid;
	grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
	grid-gap: 20px 20px;
	margin: 12px 0 24px;
`

const Hits = connectHits(({hits}) => (
	<HitsContainer>
		{hits.map(hit => (
		<Link
				to={`/profile/${hit._id}`}
				key={hit.objectID}
				style={{textDecoration: 'none'}}
			>
			<FellowCard data={hit} />
		</Link>


		))}
	</HitsContainer>
))

const SecondaryRefinementList = connectRefinementList(
	({items, refine, label}) => (
		<div>
			<Accordion elevation={0}>
				<AccordionSummary expandIcon={<ExpandMoreIcon />}>
					<Typography>{label}</Typography>
				</AccordionSummary>
				<AccordionDetails>
					<List dense disablePadding>
						{items.map(item => (
							<ListItem
								button
								key={item.label}
								onClick={() => refine(item.value)}
							>
								<ListItemIcon>
									{item.isRefined ? (
										<CheckBoxIcon />
									) : (
										<CheckBoxOutlineBlankIcon />
									)}
								</ListItemIcon>
								<ListItemText primary={item.label} />
							</ListItem>
						))}
					</List>
				</AccordionDetails>
			</Accordion>
		</div>
	)
)
