export default function getCrossroadsAccountsEndpoints(
	applicationHost,
	crossroadsConfig,
	scopes,
	crossroadsRedirectURI
) {
	const protocol = crossroadsConfig.shouldAppBeSecure ? 'https://' : 'http://'
	const crossroadsPortFragment =
		crossroadsConfig.port === 80 || crossroadsConfig.port === 443
			? ''
			: ':' + crossroadsConfig.port
	const crossroadsHost = crossroadsConfig.host + crossroadsPortFragment
	const redirectURI =
		crossroadsRedirectURI ||
		protocol + crossroadsHost + '/sessions/v0/goauthcallback'
	const accountsEndpointRoot =
		protocol + crossroadsHost + '/sessions/v0/accounts'
	const accountsEndpoints = {
		get: accountsEndpointRoot,
		destroy: accountsEndpointRoot + '/destroy',
		refresh: accountsEndpointRoot + '/refresh',
	}
	const stateObject = {
		redirectURI,
		applicationHost,
	}
	const state = new Buffer(JSON.stringify(stateObject)).toString('base64')
	const loginURI =
		'https://accounts.google.com/o/oauth2/auth?' +
		'access_type=offline' +
		'&include_granted_scopes=true' +
		'&scope=' +
		encodeURIComponent(scopes.join(' ')) +
		'&response_type=code' +
		'&client_id=' +
		crossroadsConfig.clientId +
		'&redirect_uri=' +
		encodeURI(redirectURI) +
		'&state=' +
		state
	const loginURIForced = `${loginURI}&approval_prompt=force`

	return {
		loginURI,
		loginURIForced,
		accountsEndpoints,
		baseURL: protocol + crossroadsHost,
	}
}
